

const ActionTypes = {
    SignUpUser: "SignUpUser",
    SignInUser: "SignInUser",
    RefreshToken: "RefreshToken",
    RefreshTokenError: "RefreshTokenError",
    SigninUserError: "SigninUserError",
    ChangePassword: "ChangePassword",
    ChangePasswordError: "ChangePasswordError",
    GetAllCoingeckoList: "GetAllCoingeckoList",
    GetAllCoingeckoError: "GetAllCoingeckoError",
    GetAllTokens: "GetAllTokens",
    GetAllTokensError: "GetAllTokensError",
    GetToken: "GetToken",
    GetTargetToken: "GetTargetToken",
    GetTargetTokenError: "GetTargetTokenError",
    GetTokenError: "GetTokenError",
    DeleteToken: "DeleteToken",
    DeleteTokenError: "DelteTokenError",
    ApproveToken: "ApproveToken",
    ApproveTokenError: "ApproveTokenError",
    DenyToken: "DenyToken",
    DenyTokenError: "DenyTokenError",
    UpdateToken: "UpdateToken",
    UpdateTokenError: "UpdateTokenError",
    SearchToken: "SearchToken",
    SearchTokenError: "SearchTokenError",
    AddToken: "AddToken",
    AddTokenError: "AddTokenError",
    UploadPdf: "UploadPdf",
    UploadPdfError: "UploadPdfError",
    UploadImage: "UploadImage",
    UploadImageError: "UploadImageError",
    ConfirmAddToken: "ConfirmAddToken",
    ConfirmUpdateToken: "ConfirmUpdateToken",
    SetSelectedTokenID: "SetSelectedTokenID",
    GetAllStakes: "GetAllStakes",
    GetAllStakesError: "GetAllStakesError",
    GetStake: "GetStake",
    GetStakeError: "GetStakeError",
    DeleteStake: "DeleteStake",
    DeleteStakeError: "DelteStakeError",
    UpdateStake: "UpdateStake",
    UpdateStakeError: "UpdateStakeError",
    SearchStake: "SearchStake",
    SearchStakeError: "SearchStakeError",
    AddStake: "AddStake",
    AddStakeError: "AddStakeError",
    ConfirmAddStake: "ConfirmAddStake",
    ConfirmUpdateStake: "ConfirmUpdateStake",
    UploadDocumentation: "UploadDocumentation",
    UploadDocumentationError: "UploadDocumentationError",
    ConfirmChangePassword: "ConfirmChangePassword",
    SetSelectedStakeID: "SetSelectedStakeID",
    GetOrderList: "GetOrderList",
    GetOrderListError: "GetOrderListError",
    GetTradeList: "GetTradeList",
    GetTradeListError: "GetTradeListError",
    GetBalanceList: "GetBalanceList",
    GetBalanceListError: "GetBalanceListError",
    GetStakeLogList: "GetStakeLogList",
    GetStakeLogListError: "GetStakeLogListError",
    GetNewsList: "GetNewsList",
    GetNewsListError: "GetNewsListError",
    GetUserList: "GetUserList",
    GetUserListError: "GetUserListError",
    GetTxList: "GetTxList",
    GetTxListError: "GetTxListError",
    GetCustomerStoryList: "GetCustomerStoryList",
    GetCustomerStoryListError: "GetCustomerStoryListError",
}

export default ActionTypes;